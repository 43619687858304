body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #0e1a2b;
  color: white;
  display: flex;
  justify-content: center;
}

.App {
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  margin-bottom: 50px;
  text-align: left;
}

h1 a {
  text-decoration: none;
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
  color: white; 
  display: inline-block;
}

h2 span {
  color: #00FF00;
  border-bottom: 2px solid #00FF00;
  padding-bottom: 2px;
}

.typewriter-prefix {
  color: white;
  border-bottom: none;
  padding-bottom: 0;
}

.demos-section h2 {
  color: #00FF00;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.icons a {
  margin: 0 15px;
  color: #8a8f98;
  font-size: 2rem;
  transition: color 0.3s ease;
}

.icons a:hover {
  color: white;
}

.projects-section {
  max-width: 800px;
  margin-left: 0;
}

.project-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #162336;
  border-radius: 8px;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.project-title {
  background-color: #e0e0e0;
  padding: 10px 20px;
  min-width: 200px;
  text-align: center;
  border-radius: 8px 0 0 8px;
  transition: background-color 0.3s ease;
}

.project-title a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
}

.project-item:hover .project-title {
  background-color: #ffffff;
}

.project-description {
  padding: 10px 20px;
  color: #ffffff;
  flex-grow: 1;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
}

@media (max-width: 768px) {
  .App {
    padding: 20px;
  }
  
  .project-item {
    flex-direction: column;
  }
  
  .project-title {
    width: 100%;
    min-width: unset;
    border-radius: 8px 8px 0 0;
  }
}

.more-github {
  position: relative; /* 使其相對於父元素 */
  text-align: right; /* 右對齊 */
  margin-top: 20px; /* 上方間距 */
}

.more-github a {
  color: #a4baa4; /* 連結顏色 */
  text-decoration: none; /* 去掉下劃線 */
  font-weight: bold; /* 加粗 */
}

.more-github a:hover {
  text-decoration: underline; /* 滑鼠懸停時顯示下劃線 */
}